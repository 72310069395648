export const profileRoutes = [
  {
    path: '/profil',
    name: 'Profile',
    component: () => import('@/views/Profile.vue'),
    meta: {
      title: 'Profil',
      transitionName: 'fade',
      cssClass: 'map'
    }
  },
  {
    path: '/profil/ertesitesek',
    name: 'ProfileNotifications',
    component: () => import('@/views/ProfileNotifications.vue'),
    meta: {
      title: 'Profil',
      transitionName: 'fade',
      cssClass: 'map'
    }
  },
  {
    path: '/profil/elofizetes/szamlazasi-adatok',
    name: 'ProfileSubscriptionBillingDetails',
    component: () => import('@/views/ProfileSubscriptionBillingDetails.vue'),
    meta: {
      title: 'Előfizetés',
      transitionName: 'fade',
    }
  },
  {
    path: '/profil/elofizetes/:packageName?',
    name: 'ProfileSubscription',
    component: () => import('@/views/ProfileSubscription.vue'),
    meta: {
      title: 'Előfizetés',
      transitionName: 'fade',
      cssClass: 'map'
    }
  },
];
