var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showBanner
    ? _c(
        "div",
        {
          staticClass: "app-sticky-banner",
          on: {
            click: function ($event) {
              return _vm.goToDestination()
            },
          },
        },
        [
          _vm._v(" Július 29-től Követő csomagunk tartalma bővült, ára "),
          _c("strong", [_vm._v("1100")]),
          _vm._v(
            " Ft-ra emelkedik. A többi csomagunk bővült tartalom mellett, ugyanazon az áron elérhető. Részletekért kattints > "
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }