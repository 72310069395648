import axios from 'axios';
import { store } from '../store';

export const itemModerationService = Object.assign({
  getStatuses,
  setStatus,
}, getStatuses());


function getStatuses() {
  return {
    STATUS_DEFAULT: 0,
    STATUS_APPROVED: 1,
    STATUS_REJECTED: -1,
    STATUS_DELETED: -2,
    STATUS_SENDED_TO_REVISION: 2,
  };
}

/**
 * Set status of item.
 *
 * @param Number id       ID of seller
 * @param String status   0: default
 *                        1: approved
 *                        -1: rejected
 *                        -2: deleted
 *                        2: sended to revision
 */
function setStatus(entityType, id, status, {rejectText = ''}) {
  const url  = process.env.VUE_APP_BACKEND_API + '/' + entityType + '/' + parseInt(id) + '/moderation';
  const data = { status: status };
  if (status === itemModerationService.STATUS_REJECTED) {
    data['reject_text'] = rejectText;
  }

  return axios.put(url, data).
    then((resp) => {
      if (!resp.success) {
        throw new Error('Hiba történt a moderáció közben');
      }

      return true;
    })
    .catch(error => store.dispatch('alert/show', error.message));
}
