import axios from 'axios';
import { store } from '../store';

export const productService = {
  create,
  get,
  update,
  del,
  updateStickyStatus,
  updateHungarianStatus
};

function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

/**
 * Post data of product
 *
 * @param Object data   Form data
 */
function create(postData) {
	const url  = process.env.VUE_APP_BACKEND_API + '/product';
  const formData = new FormData();

  buildFormData(formData, postData);

	return axios.post(
    url,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp;
    })
    .catch(error => store.dispatch('alert/show', error.message));
}
/**
 * Put data of product
 *
 * @param Number id
 * @param Object data   Form data
 */
function update(id, postData) {
	const url  = process.env.VUE_APP_BACKEND_API + '/product/' + id;
  const formData = new FormData();

  buildFormData(formData, postData);

	return axios.put(
    url,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok lekérésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp;
    })
    .catch(error => store.dispatch('alert/show', error.message));
}

/**
 * Get product
 *
 * @param Number id
 */
function get(id) {
  const url = process.env.VUE_APP_BACKEND_API + '/product/' + parseInt(id);

  return axios.get(url)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakot.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.product;
    });
}

function del(id) {
  const url = process.env.VUE_APP_BACKEND_API + '/product/' + parseInt(id);

  return axios.delete(url)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakot.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return true;
    })
    .catch(error => store.dispatch('alert/show', error.message));
}

function updateStickyStatus(id, isSticky) {
  const url  = process.env.VUE_APP_BACKEND_API + '/product/' + id + '/sticky';
  const formData = new FormData();
  buildFormData(formData, {is_sticky: isSticky});

  return axios.put(url, formData)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok lekérésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp;
    })
    .catch(error => store.dispatch('alert/show', error.message));
}

function updateHungarianStatus(id, isHungarian) {
  const url  = process.env.VUE_APP_BACKEND_API + '/product/' + id + '/hungarian';
  const formData = new FormData();
  buildFormData(formData, {is_hungarian: isHungarian});

  return axios.put(url, formData)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok lekérésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp;
    })
    .catch(error => store.dispatch('alert/show', error.message));
}
