<template>
  <v-app id="app" :class="cssClasses">
    <sticky-banner />
    <user-membership-banner />
    <membership-layer />

    <snackbar />
    <app-header/>
    <mobile-navigation-tabs v-if="mobileNavigationTabsIsVisible" />

    <div class="app-loader" v-if="isLoading">
      <spinner class="mr-8" size="48px" color="#676767" />
      Töltés..
    </div>
    <template v-else>
      <transition-page>
        <keep-alive>
          <router-view />
        </keep-alive>
      </transition-page>
    </template>

    <div class="notification--sw-updated" v-if="updateExists">
      <p>Frissítés érhető el az alkalmazáshoz. A legfrissebb verzió eléréséhez kattintson a FRISSÍTÉS gombra.</p>
      <a @click="refreshApp">Frissítés</a>
    </div>

    <cookie-law buttonText="Megértettem">
      <div slot="message">
        Weboldalunkon cookie-kat használunk annak érdekében, hogy jobban megismerjük a felhasználói viselkedést és érdeklődést, valamint ezek segítségével személyre szabjuk és javítsuk a reklámokat.
        Weboldalunk használatával ezen feltételeket ön automatikusan elfogdja.
        <a href="https://adatvedelem.marquardmedia.hu/adatvedelmi_tajekoztato.pdf" target="_blank">További információkat az adatkezelési tájékoztatóban olvashat.</a>
      </div>
    </cookie-law>

    <!-- Ad: Layer -->
    <dfp-slot
      id="layer"
      adUnit="vh_layer"
      :sizes="[[1, 1]]"
    />

  </v-app>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
import Spinner from 'vue-spinner/src/ClipLoader'
import AppHeader from '@/components/Header';
import MobileNavigationTabs from '@/components/MobileNavigationTabs';
import TransitionPage from './components/TransitionPage.vue';
import Snackbar from './components/Snackbar.vue';
import UserMembershipBanner from './components/UserMembershipBanner.vue';
import MembershipLayer from './components/MembershipLayer.vue';
import BrowserMixin from '@/mixins/Browser';
import UserMixin from '@/mixins/User';
import updateSiteMeta from '@/utils/update-site-meta';
import DfpSlot from '@/components/DfpSlot';
import StickyBanner from '@/components/StickyBanner.vue';

export default {
  name: 'App',
  mixins: [BrowserMixin, UserMixin],
  components: {
    AppHeader,
    CookieLaw,
    DfpSlot,
    MembershipLayer,
    MobileNavigationTabs,
    Snackbar,
    Spinner,
    UserMembershipBanner,
    TransitionPage,
    StickyBanner
  },
  data() {
    return {
      isLoading: true,
      registration: null,
      updateExists: false,
    };
  },
  created () {
    document.addEventListener('swUpdated', this.showRefreshUI);
    this.$store.dispatch('category/load');
    this.fetchLoggedInUser();
    this.initFacebookApp();
  },
  computed: {
    cssClasses: function () {
      let pageCssClass = this.$route.meta.cssClass || this.cleanRoutePath((this.$route.path == '/' ? 'homepage' : this.$route.path));
      const pageCssClassString = 'page--' + pageCssClass;

      const cssClasses = {
        'logged-in': this.$store.getters['auth/loggedIn'],
        'anonymous': !this.$store.getters['auth/loggedIn']
      };
      cssClasses[pageCssClassString] = true;

      return cssClasses;
    },
    mobileNavigationTabsIsVisible() {
      return false;

      // @NOTE: I (tibi) disabled this since in redesign2024 there has no place for this.

      // const routeName = this.$route.name;
      // return (routeName === 'Map' || routeName === 'Explore') && this.isMobile();
    },
  },
  methods: {
    fetchLoggedInUser() {
      if (localStorage.getItem('token')) {
        this.$store.dispatch('user/getCurrentUser')
          .finally(() => this.isLoading = false);
      }
      else {
        this.isLoading = false;
      }
    },
    initFacebookApp() {
      window.fbAsyncInit = () => {
        window.FB.init({
          appId            : process.env.VUE_APP_SHOPPIEGO_FB_APP_ID,
          autoLogAppEvents : true,
          xfbml            : true,
          version          : 'v8.0'
        });
      };
      (function(d, s, id){
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {return;}
        js = d.createElement(s); js.id = id;
        js.src = "//connect.facebook.net/hu_HU/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    },
    cleanRoutePath(path) {
      return path.toLowerCase()
        .replace(/^\/+/, '')
        .replace(/^-+/, '')
        .replace(/&/g, '-and-')
        .replace(/[^a-z0-9]+/g,'-')
        .replace(/--+/g, '-')
        .replace(/-+$/, '')
        .replace(/\/$/, '')
        ;
    },
    showRefreshUI(event) {
      this.registration = event.detail;
      this.updateExists = true;
    },
    refreshApp() {
      this.updateExists = false;

      if (!this.registration || !this.registration.waiting) {
        return;
      }

      this.registration.waiting.postMessage({type: 'SKIP_WAITING'});
      window.location.reload(true);
    }
  },
  watch: {
    '$route.path': {
      handler (to, from) {

        // There are 2 route changes on the subpage: undefined -> /  and  / -> /segitseg
        // For this reason we ignore the first
        if (typeof from === 'undefined' && to === '/' && to !== location.pathname) {
          return;
        }

        // The /main route has TAB switch and updatePageView handled there.
        if (to === from || to === '/main') {
          return;
        }

        let titleParts = ['Vegyélhazait'];
        if (this.$route && this.$route.meta && this.$route.meta.title) {
          titleParts.push(this.$route.meta.title);
        }
        updateSiteMeta.updatePageView(titleParts);
      },
      immediate: true
    },
    loggedIn(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.fetchLoggedInUser();
      }
    },
  },
};
</script>
