<template>
  <header
    class="header top logged-in"
    v-bind:class="cssClasses"
  >
    <v-row
      class="fill-height header-wrapper"
    >
      <div class="brand-logo--col">
        <brand-logo />
      </div>
      <div class="fill-height menu-items--col">
        <navigation />
      </div>
    </v-row>
  </header>
</template>

<script>
import BrandLogo from '@/components/BrandLogo';
import Navigation from '@/components/Navigation';
import BrowserMixin from '@/mixins/Browser';
import UserMixin from '@/mixins/User';

export default {
  name: 'Header',
  mixins: [BrowserMixin, UserMixin],
  components: {
    BrandLogo,
    Navigation
  },
  computed: {
    cssClasses: function () {
      return {
        'logged-in': this.loggedIn,
        'anonymous': !this.loggedIn
      }
    }
  }
}
</script>
