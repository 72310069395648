import axios from 'axios';
import { store } from '../store';

export const membershipService = {
  getMembership,
  createPaymentIntent,
  saveBillingDetails,
  deleteMembership,
};

function getMembership(userId) {
  const url = process.env.VUE_APP_BACKEND_API + '/membership/' + userId;

  return axios.get(url)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok betöltésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp;
    })
    .catch(error => {
      store.dispatch('alert/show', error.message);
      return false;
    });
}

function saveBillingDetails(data) {
  const url = process.env.VUE_APP_BACKEND_API + '/membership/save-billing-details';

  const formData = new FormData();
  buildFormData(formData, data);

  return axios.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok betöltésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp;
    })
    .catch(error => {
      store.dispatch('alert/show',
      {
        message: error.message,
        timeout: 8000,
        color: 'error'
      });
      return false;
    });
}

function createPaymentIntent(price) {
  const url = process.env.VUE_APP_BACKEND_API + '/membership/payment-intent';

  const formData = new FormData();
  formData.append('price', price);

  return axios.post(url, formData)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok betöltésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.payment_intent;
    })
    .catch(error => {
      store.dispatch('alert/show',
      {
        message: error.message,
        timeout: 8000,
        color: 'warning'
      });
      return false;
    });
}

function deleteMembership() {
  const url = process.env.VUE_APP_BACKEND_API + '/membership';

  return axios.delete(url)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok betöltésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp;
    })
    .catch(error => {
      store.dispatch('alert/show', error.message);
      return false;
    });
}

function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}
