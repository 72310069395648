import axios from 'axios';
import { store } from '../store';

export const eventService = {
  postFormSubmit,
  postAttachments,
  getStats
};

function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

function postFormSubmit(type, answers) {
  const url = process.env.VUE_APP_BACKEND_API + '/event/form-submit';

  const params = {
    type,
    answers
  };

  return axios.post(url, params)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok mentésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return true;
    })
    .catch(error => {
      store.dispatch('alert/show', error.message);
      return false;
    });
}

function postAttachments(files, promoLogo, promoPhoto) {
  const url = process.env.VUE_APP_BACKEND_API + '/event/attachments';

  const headers = {'Content-Type': 'multipart/form-data'}
  const formData = new FormData();
  buildFormData(formData, {files, promoLogo, promoPhoto});

  return axios.post(url, formData, {headers, withCredentials: true})
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a fájlok mentésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp;
    })
    .catch(error => {
      store.dispatch('alert/show', error.message);
      return false;
    });
}

function getStats() {
  const url = process.env.VUE_APP_BACKEND_API + '/event/stats';

  return axios.get(url, {withCredentials: true})
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok lekérése során.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp;
    })
    .catch(error => {
      store.dispatch('alert/show', error.message);
      return false;
    });
}
