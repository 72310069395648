var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isMobile() && _vm.showHamburger
    ? _c("div", { staticClass: "mobile-header" }, [
        _c(
          "div",
          {
            class: {
              "hamburger-menu--toggler": true,
              opened: _vm.mobileMenuOpened,
            },
            on: { click: _vm.toggleMobileMenu },
          },
          [
            _c("span", { staticClass: "line first" }),
            _c("span", { staticClass: "line second" }),
            _c("span", { staticClass: "line third" }),
          ]
        ),
        _vm.mobileMenuOpened
          ? _c(
              "div",
              { staticClass: "mobile-menu" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: "/piacter",
                      "active-class": _vm.exploreIsActive
                        ? `router-link-active`
                        : ``,
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.sendEvent("Piactér")
                      },
                    },
                  },
                  [_c("span", [_vm._v("Piactér")])]
                ),
                _c(
                  "router-link",
                  {
                    attrs: { to: "/elofizetes" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.sendEvent("Előfizetés")
                      },
                    },
                  },
                  [_c("span", [_vm._v("Előfizetés")])]
                ),
                _c(
                  "router-link",
                  {
                    attrs: { to: "/magazin" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.sendEvent("Magazin")
                      },
                    },
                  },
                  [_c("span", [_vm._v("Magazin")])]
                ),
                _vm.dynamicItem
                  ? _c("router-link", { attrs: { to: _vm.dynamicItem.path } }, [
                      _c("span", [_vm._v(_vm._s(_vm.dynamicItem.label))]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "divider" }),
                !_vm.loggedIn
                  ? [
                      _c(
                        "router-link",
                        {
                          attrs: { to: "/auth/login" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.sendEvent("Belépés")
                            },
                          },
                        },
                        [_c("span", [_vm._v("Belépés")])]
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: { to: "/auth/register" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.sendEvent("Regisztráció")
                            },
                          },
                        },
                        [_c("span", [_vm._v("Regisztráció")])]
                      ),
                    ]
                  : [
                      _c(
                        "router-link",
                        {
                          attrs: { to: "/profil" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.sendEvent("Profil")
                            },
                          },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.profileLabel))])]
                      ),
                      _c(
                        "router-link",
                        {
                          attrs: { to: "/auth/logout" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.logout.apply(null, arguments)
                            },
                          },
                        },
                        [_c("span", [_vm._v("Kilépés")])]
                      ),
                    ],
              ],
              2
            )
          : _vm._e(),
      ])
    : _c(
        "nav",
        { staticClass: "header-navigation" },
        [
          _c(
            "router-link",
            {
              attrs: {
                to: "/piacter",
                "active-class": _vm.exploreIsActive ? `router-link-active` : ``,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.sendEvent("Piactér")
                },
              },
            },
            [_c("span", [_vm._v("Piactér")])]
          ),
          _c(
            "router-link",
            {
              attrs: { to: "/elofizetes" },
              nativeOn: {
                click: function ($event) {
                  return _vm.sendEvent("Előfizetés")
                },
              },
            },
            [_c("span", [_vm._v("Előfizetés")])]
          ),
          _c(
            "router-link",
            {
              attrs: { to: "/magazin" },
              nativeOn: {
                click: function ($event) {
                  return _vm.sendEvent("Magazin")
                },
              },
            },
            [_c("span", [_vm._v("Magazin")])]
          ),
          _vm.dynamicItem
            ? _c("router-link", { attrs: { to: _vm.dynamicItem.path } }, [
                _c("span", [_vm._v(_vm._s(_vm.dynamicItem.label))]),
              ])
            : _vm._e(),
          !_vm.loggedIn
            ? [
                _c(
                  "div",
                  { staticClass: "auth-items" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "filled",
                        attrs: { to: "/auth/login" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.sendEvent("Belépés")
                          },
                        },
                      },
                      [_c("span", [_vm._v("Belépés")])]
                    ),
                    _c(
                      "router-link",
                      {
                        attrs: { to: "/auth/register" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.sendEvent("Regisztráció")
                          },
                        },
                      },
                      [_c("span", [_vm._v("Regisztráció")])]
                    ),
                  ],
                  1
                ),
              ]
            : [
                _c(
                  "div",
                  { staticClass: "auth-items" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "filled",
                        attrs: { to: "/profil" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.sendEvent("Profil")
                          },
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.profileLabel))])]
                    ),
                    _c(
                      "router-link",
                      {
                        attrs: { to: "/auth/logout" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.logout.apply(null, arguments)
                          },
                        },
                      },
                      [_c("span", [_vm._v("Kilépés")])]
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }