export default {
  computed: {
    loggedIn() {
      return this.$store.getters['auth/loggedIn'];
    },
    isAnonymous() {
      return !this.loggedIn;
    },
    currentUser() {
      return this.loggedIn ? this.$store.getters['user/currentUser'] : null;
    },
    currentUserIsAdmin() {
      return this.loggedIn && this.$store.getters['user/isAdmin'];
    },
    currentUserInterestedInCs() {
      return this.loggedIn ? this.$store.getters['user/currentUserInterestedInCs'] : false;
    },
  }
}
