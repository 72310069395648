import { router } from '../../router';
import { authService } from '../../services';

const token = localStorage.getItem('token');

const getNextRoute = () => {
  let nextRoute = sessionStorage.getItem('next-route-path');
  if (nextRoute === 'null' || nextRoute === '' || nextRoute === null) {
    nextRoute = false;
  }

  return nextRoute;
};

const state = {
  token: token,
  loggedIn: !!token
}

const getters = {
  loggedIn(state) {
    return state.loggedIn;
  }
}

const actions = {
  logout({commit}) {
    commit('logout');
    router.push('/');
  },
  login({commit}, {email, password}) {
    return authService.login(email, password)
      .then((token) => {
        if (typeof token !== 'undefined') {
          commit('loginSuccessful', token);
          router.push('/');
        }
      });
  },
  loginWithFb({commit}, {token}) {
    return authService.loginWithFb(token)
      .then((token) => {
        if (typeof token !== 'undefined') {
          commit('loginSuccessful', token);
          router.push('/');
        }
      });
  },
  signup({commit}, {email, password}) {
    return authService.signup(email, password)
      .then((token) => {
        if (typeof token !== 'undefined') {
          commit('loginSuccessful', token);
          const nextRoute = getNextRoute();
          window.location.href = nextRoute ? nextRoute : '/main';
        }
      });
    }
}

const mutations = {
  loginSuccessful(state, token) {
    localStorage.setItem('token', token);
    state.token = token;
    state.loggedIn = true;
  },
  logout(state) {
    localStorage.removeItem('token');
    state.token = null;
    state.loggedIn = false;
  }
}

export const auth = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
