import MixinBrowser from "@/mixins/Browser";

export const authRoutes = [
  {
    path: '/auth/register',
    name: 'Register',
    component: () => import('@/views/Auth.vue'),
    meta: {
      title: 'Regisztráció',
      transitionName: MixinBrowser.methods.isMobile() ? 'zoom' : 'fade',
      cssClass: 'auth',
      public: true
    }
  },
  {
    path: '/auth/login',
    name: 'Login',
    component: () => import('@/views/Auth.vue'),
    meta: {
      title: 'Belépés',
      transitionName: MixinBrowser.methods.isMobile() ? 'zoom' : 'fade',
      cssClass: 'auth',
      public: true
    }
  },
  {
    path: '/admin/auth',
    name: 'AdminAuth',
    component: () => import('@/views/AdminAuth.vue'),
    meta: {
      title: 'Belépés',
      cssClass: 'auth--admin'
    }
  },
];
