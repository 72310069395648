import { productService } from '../../services';

const state = {
  onlineProducts: [],
  offlineProducts: [],
  displayedMapProductIds: [],
  product: null,
}

const getters = {
  currentProduct(state) {
    return state.product;
  }
}

const actions = {
	async getOnlineProducts ({commit}, {page, limit}) {
    const items = await productService.getOnlineProducts(page, limit);

    commit('pushOnlineProducts', items);

    return items.length;
  },
  async getNearbyOfflineProducts ({state, rootState, commit}) {
    const items = await productService.getNearbyOfflineProducts(
      rootState.map.center.lat,
      rootState.map.center.lng,
      rootState.map.zoom,
      state.displayedMapProductIds
    );

    commit('pushOfflineProducts', items);
  },
  async getProduct ({commit}, {id}) {
    const item = await productService.getProduct(id);

    commit('setProduct', item);

    return item;
	},
  async postLike (commit, {id}) {
    const resp = await productService.rating(id, 'like');

    return resp;
  },
  async postDislike (commit, {id}) {
    const resp = await productService.rating(id, 'dislike');

    return resp;
  },
  setStatus (commit, {id, status}) {
    return productService.setStatus(id, status);
  },
  rejectProduct (commit, {id, rejectText}) {
    return productService.rejectProduct(id, rejectText);
  }
}

const mutations = {
	pushOnlineProducts (state, items) {
		items.forEach(item => state.onlineProducts.push(item));
  },
  pushOfflineProducts (state, items) {
		items.forEach(item => {
      state.offlineProducts.push(item);
      state.displayedMapProductIds.push(item.id);
    });
  },
  setProduct (state, item) {
		state.product = item;
	},
}

export const product = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
