import 'mutationobserver-shim'

import Vue from 'vue'
import './assets/scss/styles.scss';

import './services/_axios-interceptors';
import { router } from './router'
import { store } from './store'

import App from './App.vue'
import vuetify from './plugins/vuetify';
import './registerServiceWorker'

import Vue2TouchEvents from 'vue2-touch-events'
import VModal from 'vue-js-modal'
import VueSilentbox from 'vue-silentbox'

Vue.use(Vue2TouchEvents)
Vue.use(VModal)
Vue.use(VueSilentbox)

// Google Maps
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  }
});

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    getActualSeason() {
      return this.$seasonals[this.$season] || {};
    }
  }
});
Vue.prototype.$season = 'vegyeltavaszit2023';
Vue.prototype.$seasonals = {
  easter2021: {
    id: 1,
    paths: {
      main: '/vegyeltavaszit',
      product: '/vegyeltavaszit/termek/',
      productEdit: '/vegyeltavaszit/termek/%slug/szerkesztes',
      category: '/vegyeltavaszit/kategoria/',
    },
    title: {
      main: 'Ha április, akkor Vegyél tavaszit! Válogass csodás tavaszi kincsek közül, és lepd meg velük szeretteidet névnapra, születésnapra, Anyák napjára vagy akár Gyereknapra!',
      sub: ''
    },
    gaActionName: 'easter_feed',
    gaActionCard: 'easter_card',
    ends: new Date('05/03/2021 00:00:00'),
    endsMessage: 'A tavaszi vásárunk véget ért.',
  },
  xmas2021: {
    id: 3,
    paths: {
      main: '/adventivasar',
      product: '/adventivasar/termek/',
      productEdit: '/adventivasar/termek/%slug/szerkesztes',
      category: '/adventivasar/kategoria/',
    },
    title: {
      main: 'Megnyílt online adventi vásárunk! Válogass most hazai alkotók termékeiből, és lepd meg vele szeretteid karácsonyra!',
      sub: ''
    },
    gaActionName: 'xmas_feed',
    gaActionCard: 'xmas_card',
    ends: new Date('12/31/2023 00:00:00'),
    endsMessage: 'Az adventi vásárunk véget ért.',
  },
  vegyeltavaszit2023: {
    id: 4,
    paths: {
      main: '/vegyeltavaszit',
      product: '/vegyeltavaszit/termek/',
      productEdit: '/vegyeltavaszit/termek/%slug/szerkesztes',
      category: '/vegyeltavaszit/kategoria/',
    },
    title: {
      main: 'Megnyílt online tavaszi vásárunk! Válogass most hazai alkotók termékeiből, és lepd meg vele szeretteidet!',
      sub: ''
    },
    gaActionName: 'easter_2023_feed',
    gaActionCard: 'easter_2023_card',
    ends: new Date('12/31/2023 00:00:00'),
    endsMessage: 'A tavaszi vásárunk véget ért.',
  }
};

window.AppInstance = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
