<template>
  <div
    v-if="showBanner"
    class="membership-banner membership-banner--not-fixed"
    :class="bannerClasses"
    @click="onClick"
  >
    <div class="container">
      <template v-if="!sellerProfileIsApproved">
        Kedves Eladónk!
        Boltod jóváhagyásra vár.
        A boltod elfogadását követően tudod rendezni a havi 1100 Ft-os kezelési díjat, mely segítségével posztolhatsz Facebook csoportunkban!
      </template>
      <template v-else-if="!hasActiveMembership">
        Kedves Eladónk!
        Még nincs aktív előfizetésed.
        Ha továbbra is szeretnél posztolni zárt Facebook csoportunkban, azt a havi kezelési díj ellenében teheted meg.
      </template>
      <template v-else-if="nextChargeDate">
        Előfizetésed automatikusan megújul: {{ nextChargeDate }}
      </template>
      <template v-else>
        Előfizetésed nem újul meg, lejárat ideje: {{ validDateText }}
      </template>
    </div>
  </div>
</template>

<script>
import UserMixin from '@/mixins/User';
import MembershipMixin from '@/mixins/Membership';

export default {
  name: 'UserMembershipBanner',
  mixins: [UserMixin, MembershipMixin],
  computed: {
    hasSellerProfile() {
      return this.currentUser ? !!this.currentUser.seller_profile : false;
    },
    sellerProfileIsApproved() {
      return this.hasSellerProfile && this.currentUser.seller_profile.status === 1;
    },
    nextChargeDate() {
      if (!this.membershipNextCharge) {
        return null;
      }

      const year = this.membershipNextCharge.substring(0, 4);
      const month = parseInt(this.membershipNextCharge.substring(5, 7));
      const day = this.membershipNextCharge.substring(8, 10);

      const monthNames = ['', 'január', 'február', 'március', 'április', 'május', 'június',
        'július', 'augusztus', 'szeptember', 'október', 'november', 'december'];

      return year + '. ' + monthNames[month] + ' ' + day + '.';
    },
    validDateText() {
      if (!this.membershipValidDate) {
        return null;
      }

      const year = this.membershipValidDate.substring(0, 4);
      const month = parseInt(this.membershipValidDate.substring(5, 7));
      const day = this.membershipValidDate.substring(8, 10);

      const monthNames = ['', 'január', 'február', 'március', 'április', 'május', 'június',
        'július', 'augusztus', 'szeptember', 'október', 'november', 'december'];

      return year + '. ' + monthNames[month] + ' ' + day + '.';
    },
    showBanner() {
      return this.currentUser && this.hasSellerProfile && !this.currentUserIsAdmin;
    },
    bannerClasses() {
      return {
        'membership-banner--green': this.hasActiveMembership,
        'membership-banner--red': !this.hasActiveMembership,
      }
    }
  },
  methods: {
    onClick() {
      if (!this.sellerProfileIsApproved) {
        return null;
      }
      else if (this.hasActiveMembership) {
        this.$router.push('/profil');
      }
      else {
        this.$router.push('/profil/elofizetes');
      }
    },
  },
}
</script>
