const state = {
  value: ''
}

const getters = {
  value(state) {
    return state.value;
  }
}

const actions = {
  clear({commit}) {
    commit('value', '');
  }
}

const mutations = {
  value(state, value) {
    state.value = value;
  }
}

export const searchInput = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
