import axios from 'axios';
import { store } from '../store';

export const categoryService = {
  getCategories,
};

/**
 * Get user data from backend
 */
function getCategories() {
  const url = process.env.VUE_APP_BACKEND_API + '/categories';

  return axios.get(url)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok lekérésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.categories;
    })
    .catch(error => store.dispatch('alert/show', error.message));
}
