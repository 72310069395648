import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { staticClass: "header top logged-in", class: _vm.cssClasses },
    [
      _c(VRow, { staticClass: "fill-height header-wrapper" }, [
        _c("div", { staticClass: "brand-logo--col" }, [_c("brand-logo")], 1),
        _c(
          "div",
          { staticClass: "fill-height menu-items--col" },
          [_c("navigation")],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }