import axios from "axios";
import { store } from '../store';

export const homeService = {
  getHomeData,
};

function getHomeData() {
  const url = process.env.VUE_APP_BACKEND_API + "/home";

  return axios
    .get(url)
    .then((resp) => {
      if (resp instanceof Error) {
        throw new Error("Hiba az adatok betöltésekor.");
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp
    })
    .catch((error) => {
      store.dispatch("alert/show", error.message);
      return false;
    });
}