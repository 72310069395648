import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showBanner
    ? _c(
        "div",
        { staticClass: "membership-layer-mask", on: { click: _vm.close } },
        [
          _c(
            "div",
            { staticClass: "layer" },
            [
              _c(
                VBtn,
                {
                  attrs: { absolute: "", icon: "", top: "", right: "" },
                  on: { click: _vm.close },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/images/icons/x.svg"),
                      alt: "",
                    },
                  }),
                ]
              ),
              _c("strong", [
                _vm._v(
                  "Kedves " + _vm._s(_vm.currentUser.seller_profile.title) + "!"
                ),
              ]),
              _c("br"),
              _c("br"),
              !_vm.sellerProfileIsApproved
                ? [
                    _c("p", [
                      _vm._v(
                        " Boltod jóváhagyásra vár. A boltod elfogadását követően tudod rendezni a havi 1100 Ft-os kezelési díjat, mely segítségével posztolhatsz Facebook csoportunkban! "
                      ),
                    ]),
                  ]
                : [
                    _c("p", [
                      _vm._v(
                        " 2023. január 9-től weboldalunkon az eladói márkaprofil havi 1100 Ft /hó kezelési díj ellenében vehető igénybe. Új boltként ennek befizetésével tudsz regisztrálni, a már regisztrált eladók a havi kezelési díj megváltása után maradnak elérhetőek. "
                      ),
                    ]),
                    _c(
                      VBtn,
                      {
                        staticClass: "action",
                        on: { click: _vm.onClickButton },
                      },
                      [_vm._v("Előfizetek")]
                    ),
                  ],
            ],
            2
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }