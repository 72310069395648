import axios from 'axios';
import { store } from '../store';

export const userService = {
  getCurrentUser,
  updateCurrentUser,
  getUserInfo,
  deleteUser,
};

/**
 * Get user data from backend
 */
function getCurrentUser() {
  const url = process.env.VUE_APP_BACKEND_API + '/user';

  return axios.get(url)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok lekérésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.user;
    })
    .catch(error => store.dispatch('alert/show', error.message));
}

function updateCurrentUser(postData) {
  const url = process.env.VUE_APP_BACKEND_API + '/user';

  const formData = new FormData();
  buildFormData(formData, postData);

  return axios.post(url, formData)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok mentésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.user;
    })
    .catch(error => store.dispatch('alert/show', error.message));
}

/**
 * Get user data from backend
 */
function getUserInfo(userEmail, silently = false) {
  const url = process.env.VUE_APP_BACKEND_API + '/user';

  return axios.get(url, {params: {email: userEmail}})
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok lekérésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.user;
    })
    .catch(error => {
      if (!silently) {
        store.dispatch('alert/show', error.message)
      }

      return false;
    });
}

function deleteUser() {
  const url = process.env.VUE_APP_BACKEND_API + '/user';

  return axios.delete(url)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok lekérésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.user;
    })
    .catch(error => {
      store.dispatch('alert/show', error.message)

      return false;
    });
}

function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}
