import Vue from 'vue';
import { StripePlugin } from '@vue-stripe/vue-stripe';
// import AppService from '@/services/App';

const state = {
  isLoading: false,
  stripeLoaded: false,
}

const getters = {
  isLoading(state) {
    return state.isLoading;
  },
  stripeLoaded(state) {
    return state.stripeLoaded;
  },
}

const actions = {
  loadStripe(store) {
    return new Promise((resolve) => {
      if (store.getters['stripeLoaded']) {
        return resolve();
      }

      const scriptEl = document.createElement('script');
      scriptEl.setAttribute(
        'src',
        'https://js.stripe.com/v3'
      );
      scriptEl.setAttribute('type', 'text/javascript');
      scriptEl.setAttribute('async', true);
      scriptEl.onload = () => {
        const stripeOptions = {
          testMode: process.env.NODE_ENV !== 'production',
          pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
          locale: store.rootGetters['app/language'],
        };

        Vue.use(StripePlugin, stripeOptions);

        store.commit('setStripeLoaded', true);

        resolve();
      };

      document.body.appendChild(scriptEl);
    });
  },
}

const mutations = {
  isLoading(state, value) {
    state.isLoading = value;
  },
  setStripeLoaded(state, value) {
    state.stripeLoaded = value;
  },
}

export const app = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
