import axios from 'axios';
import { store } from '../store';

export const sellerService = {
  create,
  get,
  update,
  del,
  rating,
  saveDraft,
  getDraft,
  updateStickyStatus,
};

function create(postData) {
	const url  = process.env.VUE_APP_BACKEND_API + '/seller';
  const formData = new FormData();

  buildFormData(formData, postData);

	return axios.post(
    url,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp;
    })
    .catch(error => store.dispatch('alert/show', error.message));
}

function get(id) {
  const url = process.env.VUE_APP_BACKEND_API + '/seller/' + parseInt(id);

  return axios.get(url)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok lekérésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.seller
    });
}

function update(id, postData) {
	const url  = process.env.VUE_APP_BACKEND_API + '/seller/' + id;
  const formData = new FormData();

  buildFormData(formData, postData);

	return axios.put(
    url,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok lekérésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp;
    })
    .catch(error => store.dispatch('alert/show', error.message));
}

function del(id) {
  const url = process.env.VUE_APP_BACKEND_API + '/seller/' + parseInt(id);

  return axios.delete(url)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a mávelet végrehajtásakot.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }
    });
}

function rating(id, value) {
  if (value < 1 || value > 5) {
    throw new Error('Invalid rating value: ' + value);
  }

  const url = process.env.VUE_APP_BACKEND_API + '/seller/' + parseInt(id) + '/rating';

  const formData = new FormData();
  formData.append('rating', value);

  return axios.post(url, formData)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Sikertelen művelet.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp;
    })
    .catch(error => store.dispatch('alert/show', error.message));
}

function saveDraft(postData) {
  const url  = process.env.VUE_APP_BACKEND_API + '/seller/draft';
  const formData = new FormData();

  buildFormData(formData, postData);

  return axios.post(
    url,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp;
    });
}

function getDraft() {
  const url = process.env.VUE_APP_BACKEND_API + '/seller/draft';

  return axios.get(url)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok lekérésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      if (!resp.seller) {
        return;
      }

      return resp.seller
    });
}

function updateStickyStatus(id, isSticky) {
  const url  = process.env.VUE_APP_BACKEND_API + '/seller/' + id + '/sticky';
  const formData = new FormData();
  buildFormData(formData, {is_sticky: isSticky});

  return axios.put(url, formData)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok lekérésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp;
    })
    .catch(error => store.dispatch('alert/show', error.message));
}

function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}
