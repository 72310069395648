export const eventsRoutes = [
  {
    path: '/esemenyek',
    name: 'Events',
    component: () => import('@/views/EventsSubscribeForm.vue'),
    meta: {
      title: 'Események',
      transitionName: 'fade',
      cssClass: 'event-main',
      public: true
    }
  },
  {
    path: '/esemenyek/stat',
    name: 'EventStats',
    component: () => import('@/views/EventStats.vue'),
    meta: {
      title: 'Események statisztika',
      transitionName: 'fade',
      cssClass: 'event-stats',
      public: false
    }
  },
  {
    path: '/esemeny/:slug',
    name: 'Event',
    component: () => import('@/views/Event.vue'),
    meta: {
      title: 'Esemény',
      transitionName: 'fade',
      cssClass: 'event-eventpage',
      public: true
    }
  },
];
