import axios from 'axios';
import { store } from '../store'

const isDevelopment = (process.env.NODE_ENV === 'development');

axios.interceptors.request.use(function (config) {
	const token = localStorage.getItem('token');

	if (token) {
		config.headers.Authorization = 'Bearer ' + token;
	}

	return config;
});

axios.interceptors.response.use(
	// 2xx
	function (response) {
		if (response.data && response.data.code && response.data.code === 401) {
			store.dispatch('auth/logout');
		}
		return response.data;
	},
	// 3xx-4xx
	function (error) {
		if (error.response && error.response.status === 401) {
			store.dispatch('auth/logout');
		} else if (error.response && error.response.status === 404) {
			return {
        success: false,
        error: 'A kért adat nem található.'
      };
		} else {
			return (isDevelopment ? error : 'Ismeretlen hiba');
		}
	}
);
