export function sendGaEvent (action, category, label, value) {
  if (window.dataLayer && typeof window.gtag == 'function' && action) {
    const gaObject = {};

    if (category) {
      gaObject.event_category = category;
    }
    if (label) {
      gaObject.event_label = label;
    }
    if (typeof value === 'number' && value > -1) {
      gaObject.value = value;
    }

    window.gtag('event', action, gaObject);
  }
}
