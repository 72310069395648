function updatePageTitle (title) {
  let $title = document.querySelector('title');
  if ($title) {
    $title.textContent = title;
  }
}

function updatePageView (titleParts) {
  let title = titleParts.reverse().join(' | ');
  updatePageTitle(title);

  /* global gtag */
  if (window.dataLayer && typeof gtag == 'function') {
    gtag('js', new Date());
    gtag('config', 'UA-9857668-37', {
      'page_title': title,
      'page_path': window.location.pathname
    });
  }

  if (typeof window.fbq !== 'undefined') {
    window.fbq('track', 'PageView');
  }

  /* global pp_gemius_identifier_original */
  if (typeof window.pp_gemius_hit !== 'undefined') {
    window.pp_gemius_hit(pp_gemius_identifier_original);
  }
}

export default {
  updatePageView,
  updatePageTitle
};
