var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showBanner
    ? _c(
        "div",
        {
          staticClass: "membership-banner membership-banner--not-fixed",
          class: _vm.bannerClasses,
          on: { click: _vm.onClick },
        },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              !_vm.sellerProfileIsApproved
                ? [
                    _vm._v(
                      " Kedves Eladónk! Boltod jóváhagyásra vár. A boltod elfogadását követően tudod rendezni a havi 1100 Ft-os kezelési díjat, mely segítségével posztolhatsz Facebook csoportunkban! "
                    ),
                  ]
                : !_vm.hasActiveMembership
                ? [
                    _vm._v(
                      " Kedves Eladónk! Még nincs aktív előfizetésed. Ha továbbra is szeretnél posztolni zárt Facebook csoportunkban, azt a havi kezelési díj ellenében teheted meg. "
                    ),
                  ]
                : _vm.nextChargeDate
                ? [
                    _vm._v(
                      " Előfizetésed automatikusan megújul: " +
                        _vm._s(_vm.nextChargeDate) +
                        " "
                    ),
                  ]
                : [
                    _vm._v(
                      " Előfizetésed nem újul meg, lejárat ideje: " +
                        _vm._s(_vm.validDateText) +
                        " "
                    ),
                  ],
            ],
            2
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }