import { VBtn } from 'vuetify/lib/components/VBtn';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VSnackbar,
    {
      attrs: {
        top: "",
        "multi-line": _vm.multiline,
        timeout: _vm.timeout,
        color: _vm.color,
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c("span", { domProps: { innerHTML: _vm._s(_vm.message) } }),
      _c(
        VBtn,
        {
          attrs: { slot: "action", text: "" },
          on: {
            click: function ($event) {
              _vm.show = false
            },
          },
          slot: "action",
        },
        [_vm._v("Bezár")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }