export default store => {
  store.subscribe((mutation) => {
    if (mutation.type === 'auth/logout') {
      store.commit('user/setCurrentUser', null)
    }

    if (mutation.type === 'membership/setMembershipData') {
      store.commit('user/updateMembershipData', mutation.payload)
    }
  })
}
