<template>
  <v-snackbar
    top
    :multi-line="multiline"
    :timeout="timeout"
    v-model="show"
    :color="color"
  >
    <span v-html="message"></span>
    <v-btn text @click="show = false" slot="action">Bezár</v-btn>
  </v-snackbar>
</template>

<script>
const snackColors = ['success', 'info', 'error', 'cyan darken-2', 'warning'];

export default {
  data () {
    return {
      show: false,
      message: null,
      color: 'info',
      multiline: false,
      timeout: 6000,
      defaults: {}
    }
  },
  created () {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'alert/setMessage') {
        this.defaults.timeout = this.timeout;
        this.defaults.multiline = this.multiline;
        this.defaults.color = this.color;

        if (state.alert.options.timeout) {
          this.timeout = parseInt(state.alert.options.timeout);
        }

        if (state.alert.options.multiline) {
          this.multiline = !!state.alert.options.multiline;
        }

        if (
          state.alert.options.color
          && snackColors.indexOf(state.alert.options.color) > -1
        ) {
          this.color = state.alert.options.color;
        } else {
          this.color = 'info';
        }

        this.message = state.alert.message
        this.show = true;
      }
    })
  },
  watch: {
    show: function (newValue) {
      if (newValue === false) {
        // Timeout needed because of SnackBar transition..
        setTimeout(() => {
          this.timeout = this.defaults.timeout;
          this.multiline = this.defaults.multiline;
          this.color = this.defaults.color;

          this.defaults = {};
        }, 500);
      }
    }
  }
}
</script>
