var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    { staticClass: "brand-logo", attrs: { to: "/", title: "Vegyél hazait" } },
    [
      _c("img", {
        attrs: {
          src: require("@/assets/images/logo-black-background.svg"),
          width: "90",
          height: "90",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }