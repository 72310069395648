var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "ad-zone",
    staticStyle: { display: "none" },
    attrs: { id: _vm.id },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }