import axios from 'axios';
import { store } from '../store';

export const helpService = {
  getHelp,
};

function getHelp() {
  const url = process.env.VUE_APP_BACKEND_API + '/faq';

  return axios.get(url)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok betöltésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.faq;
    })
    .catch(error => {
      store.dispatch('alert/show', error.message);
      return false;
    });
}
