export const seasonalRoutes = [
  // SEASONAL ADMIN
  {
    path: '/admin/seasonals',
    name: 'Seasonals',
    component: () => import('../views/SeasonalsAdmin.vue'),
    meta: {
      title: 'Különleges események - Admin lista',
      cssClass: 'seasonal seasonal--admin seasonal--item',
      public: true,
      restricted: 'vegyeltavaszit2023mm'
    }
  },
  {
    path: '/admin/seasonals/new',
    name: 'SeasonalNew',
    component: () => import('../views/SeasonalEdit.vue'),
    meta: {
      title: 'Különleges események - Új létrehozása',
      cssClass: 'seasonal seasonal--admin seasonal--item new',
      public: true,
      restricted: 'vegyeltavaszit2023mm'
    }
  },
  {
    path: '/admin/seasonals/:seasonalItemId',
    name: 'SeasonalEdit',
    component: () => import('../views/SeasonalEdit.vue'),
    meta: {
      title: 'Különleges események - Szerkesztése',
      cssClass: 'seasonal seasonal--admin seasonal--item seasonal--item--edit',
      public: true,
      restricted: 'vegyeltavaszit2023mm'
    }
  },
  {
    path: '/admin/seasonals/:seasonalItemId/stat',
    name: 'SeasonalStat',
    component: () => import('../views/SeasonalStat.vue'),
    meta: {
      title: 'Különleges események - Statisztika',
      cssClass: 'seasonal seasonal--admin seasonal--item--stat',
      public: true,
      restricted: 'vegyeltavaszit2023mm'
    }
  },
  {
    path: '/admin/seasonals/:seasonalItemId/new',
    name: 'SeasonalCategoryNew',
    component: () => import('../views/SeasonalCategoryEdit.vue'),
    meta: {
      title: 'Különleges események - Kategória létrehozása',
      cssClass: 'seasonal seasonal--admin seasonal--item--category new',
      public: true,
      restricted: 'vegyeltavaszit2023mm'
    }
  },
  {
    path: '/admin/seasonals/:seasonalItemId/:categoryId',
    name: 'SeasonalCategoryEdit',
    component: () => import('../views/SeasonalCategoryEdit.vue'),
    meta: {
      title: 'Különleges események - Kategória szerkesztése',
      cssClass: 'seasonal seasonal--admin seasonal--item--category edit',
      public: true,
      restricted: 'vegyeltavaszit2023mm'
    }
  },

 // VEGYEL HAZAIT - VEGYEL TAVASZIT
 {
   path: '/vegyeltavaszit',
   name: 'Seasonal',
   component: () => import('../views/Seasonal.vue'),
   meta: {
     title: 'Vegyél tavaszit',
     transitionName: 'fade',
     cssClass: 'seasonal seasonal--frontend seasonal--easter',
     public: true
   }
 },
 {
   path: '/vegyeltavaszit-feliratkozas',
   name: 'SeasonalTeaserEaster',
   component: () => import('../views/SeasonalTeaserEaster.vue'),
   meta: {
     title: 'Vegyél tavaszit',
     transitionName: 'fade',
     cssClass: 'seasonal seasonal--frontend seasonal--teaser',
     public: true
   }
 },
 {
   path: '/vegyeltavaszit/kategoria',
   name: 'SeasonalCategoryMain',
   component: () => import('../views/SeasonalCategoryMain.vue'),
   children: [
     {
       path: ':categoryId',
       name: 'SeasonalCategory',
       component: () => import('../views/SeasonalCategory.vue'),
       meta: {
         title: 'Vegyél tavaszit - Kategóriák',
         cssClass: 'seasonal seasonal--frontend seasonal-category',
         public: true
       }
     },
   ]
 },
 {
   path: '/vegyeltavaszit/termek/letrehozas',
   name: 'SeasonalProductCreate',
   component: () => import('../views/SeasonalProductEdit.vue'),
   meta: {
     title: 'Vegyél tavaszit - Termék létrehozása',
     transitionName: 'fade',
     cssClass: 'seasonal seasonal--admin seasonal--product--edit seasonal--product--edit--easter create',
     public: true
   }
 },
 {
   path: '/vegyeltavaszit/termek/:slug',
   name: 'SeasonalProduct',
   component: () => import('../views/SeasonalProduct.vue'),
   meta: {
     title: 'Vegyél tavaszit - Termék oldal',
     transitionName: 'fade',
     cssClass: 'seasonal seasonal--frontend seasonal--product seasonal--product--easter',
     public: true
   }
 },
 {
   path: '/vegyeltavaszit/termek/:slug/szerkesztes',
   name: 'SeasonalProductEdit',
   component: () => import('../views/SeasonalProductEdit.vue'),
   meta: {
     title: 'Vegyél tavaszit - Termék szerkesztése',
     transitionName: 'fade',
     cssClass: 'seasonal seasonal--admin seasonal--product--edit seasonal--product-edit--easter',
     restricted: 'vegyeltavaszit2023mm'
   }
 },

  //// VEGYEL HAZAIT - ADVENTI VASAR
  //{
  //  path: '/adventivasar',
  //  name: 'Seasonal',
  //  component: () => import('../views/Seasonal.vue'),
  //  meta: {
  //    title: 'Adventi vásár',
  //    transitionName: 'fade',
  //    cssClass: 'seasonal seasonal--frontend seasonal--xmas',
  //    public: true
  //  }
  //},
  ///*
  //{
  //  path: '/adventivasar-feliratkozas',
  //  name: 'SeasonalTeaserEaster',
  //  component: () => import('../views/SeasonalTeaserXmas.vue'),
  //  meta: {
  //    title: 'Adventi vásár',
  //    transitionName: 'fade',
  //    cssClass: 'seasonal seasonal--frontend seasonal--teaser',
  //    public: true
  //  }
  //},
  //*/
  //{
  //  path: '/adventivasar/kategoria',
  //  name: 'SeasonalCategoryMain',
  //  component: () => import('../views/SeasonalCategoryMain.vue'),
  //  children: [
  //    {
  //      path: ':categoryId',
  //      name: 'SeasonalCategory',
  //      component: () => import('../views/SeasonalCategory.vue'),
  //      meta: {
  //        title: 'Adventi vásár - Kategóriák',
  //        cssClass: 'seasonal seasonal--frontend seasonal-category',
  //        public: true
  //      }
  //    },
  //  ]
  //},
  //{
  //  path: '/adventivasar/termek/letrehozas',
  //  name: 'SeasonalProductCreate',
  //  component: () => import('../views/SeasonalProductEdit.vue'),
  //  meta: {
  //    title: 'Adventi vásár - Termék létrehozása',
  //    transitionName: 'fade',
  //    cssClass: 'seasonal seasonal--admin seasonal--product--edit seasonal--product--edit--xmas create',
  //    public: true
  //  }
  //},
  //{
  //  path: '/adventivasar/termek/:slug',
  //  name: 'SeasonalProduct',
  //  component: () => import('../views/SeasonalProduct.vue'),
  //  meta: {
  //    title: 'Adventi vásár - Termék oldal',
  //    transitionName: 'fade',
  //    cssClass: 'seasonal seasonal--frontend seasonal--product seasonal--product--xmas',
  //    public: true
  //  }
  //},
  //{
  //  path: '/adventivasar/termek/:slug/szerkesztes',
  //  name: 'SeasonalProductEdit',
  //  component: () => import('../views/SeasonalProductEdit.vue'),
  //  meta: {
  //    title: 'Adventi vásár - Termék szerkesztése',
  //    transitionName: 'fade',
  //    cssClass: 'seasonal seasonal--admin seasonal--product--edit seasonal--product-edit--xmas',
  //    restricted: 'vegyeltavaszit2023mm'
  //  }
  //},

  {
    path: '/esemeny-veget-ert',
    name: 'SeasonalIsOver',
    component: () => import('../views/SeasonalIsOver.vue'),
    meta: {
      title: 'Tavaszi vásár | Esemény vége',
      transitionName: 'fade',
      cssClass: 'seasonal seasonal--frontend seasonal--easter',
      public: true
    }
  },
];
