import { userService } from '../../services';

const state = {
  currentUser: null,
  rights: [],
  isAdmin: false,
}

const getters = {
  currentUser(state) {
    return state.currentUser;
  },
  assignedUser(state) {
    return state.assignedUser;
  },
  isAdmin(state) {
    return state.isAdmin;
  },
  currentUserInterestedInCs(state) {
    if (!state.currentUser) {
      return false;
    }

    return state.currentUser.interested_in_cs;
  },
  hasRight: (state) => (right) => {
    for (var key in state.rights) {
      if (key === right && state.rights[right] === 1) {
        return true;
      }
    }

    return false;
  }
}

const actions = {
  getCurrentUser({commit}) {
    return userService.getCurrentUser()
      .then(user => commit('setCurrentUser', user));
  },
  update({commit}, values) {
    return userService.updateCurrentUser(values)
      .then(user => commit('setCurrentUser', user));
  },
  deleteUser() {
    return userService.deleteUser();
  }
}

const mutations = {
  setCurrentUser(state, user) {
    if (user) {
      state.rights = Object.freeze(JSON.parse(atob(user.rights)));
      state.isAdmin = Object.freeze(getters.hasRight(state)('admin'));

      delete user.rights;
      state.currentUser = Object.freeze(user);
    }
    else {
      state.currentUser = null;
    }
  },
  setCurrentUserSellerProfile(state, sellerProfile) {
    let user = Object.assign({}, state.currentUser);
    user.seller_profile = sellerProfile;

    state.currentUser = Object.freeze(user);
  },
  updateMembershipData(state, updatedMembershipData) {
    let unlockedObject = JSON.parse(JSON.stringify(state.currentUser));
    unlockedObject.membership = updatedMembershipData;

    state.currentUser = Object.freeze(unlockedObject);
  },
}

export const user = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
