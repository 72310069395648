import axios from 'axios';
import { store } from '../store';

export const listService = {
  getList,
  getNearbyOfflineSellers,
  getRandom,
  getCancelToken,
};

function getList({entityTypes = [], page = 1, limit = 20, term = '', categoryIds = [], userId = 0, status = 1}) {
  const url = process.env.VUE_APP_BACKEND_API + '/list';
  const params = {
    params: {
      entity_types: entityTypes,
      term,
      category: categoryIds,
      user_id: userId,
      status,
      page,
      limit
    }
  };

  return axios.get(url, params)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok lekérésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp;
    })
    .catch(error => store.dispatch('alert/show', error.message));
}

function getNearbyOfflineSellers(cancelToken, lat, lng, zoom, {ignoredIds = [], term = '', categoryIds = []}) {
  const url = process.env.VUE_APP_BACKEND_API + '/list/map';
  const params = {
    cancelToken,
    params: {
      lat,
      lng,
      zoom,
      term,
      category: categoryIds,
      ignore: ignoredIds.join(',')
    }
  };

  return axios.get(url, params)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok lekérésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      // Create marker position object from lat and lng
      resp.items.forEach((obj, i, array) => {
        array[i].position = {
          lat: obj.lat,
          lng: obj.lng
        }
      });

      return resp.items;
    })
    .catch(error => error.message ? store.dispatch('alert/show', error.message) : null);
}

function getRandom({limit = 4, sellers = false}) {
  const url = process.env.VUE_APP_BACKEND_API + '/list/random';
  const params = {
    params: { limit, sellers }
  };

  return axios.get(url, params)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba az adatok lekérésekor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp;
    })
    .catch(error => store.dispatch('alert/show', error.message));
}

function getCancelToken() {
  return axios.CancelToken.source();
}
