import axios from 'axios';
import { store } from '../store';

export const cmsService = {
  getHomeArticles,
  getArticle,
  getPreviewArticle,
  getListOf,
};

const cmsUrl = process.env.VUE_APP_CMS_API;

function getHomeArticles(page = 1, limit = 20) {
  const endpoint = `${cmsUrl}/home?page=${page}&limit=${limit}`;

  return axios.get(endpoint)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Error during request.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return {
        items: resp.items.items,
        pagination: resp.items.pagination
      };
    })
    .catch(error => store.dispatch('alert/show', error.message));
}

function getPreviewArticle(id) {
  return getArticle(id, 'preview');
}

function getArticle(slugOrId, columnPath) {
  let endpoint = cmsUrl + '/article'

  if (columnPath && columnPath !== 'cikk') {
    endpoint += `/${columnPath}`
  }

  let theSlugOrId = slugOrId

  // This block cannot be applied to slug like '/szepseg/5-pro-tipp-telire'
  if (columnPath == 'cikk') {
    const theId = /^(\d+)-.+|.+-(\d+)$/.exec(slugOrId)
    if (
      Array.isArray(theId)
    ) {
      if (theId[1] !== undefined) {
        theSlugOrId = theId[1]
      } else if (theId[2] !== undefined) {
        theSlugOrId = theId[2]
      }
    }
  }
  endpoint += `/${theSlugOrId}`

  return axios.get(endpoint)
    .then((response) => {
      if (
        Object.prototype.toString.call(response) !== '[object Object]' ||
        (
          response &&
          typeof response.success === 'boolean' &&
          !response.success
        )
      ) {
        return false
      }

      return response.article
    })
    .catch((e) => {
      return e
    })
}



function getListOf ({
  columnist = 0,
  tags = [],
  term = null,
  limit = 20,
  page = 1,
  exclude = []
}) {
  const getParams = { page, limit }
  if (columnist > 0) {
    getParams.columnist = columnist
  }

  if (Array.isArray(tags) && tags.length) {
    getParams.tags = tags
  }

  if (term !== null) {
    getParams.term = term
  }

  if (Array.isArray(exclude) && exclude.length) {
    getParams.exclude = exclude
  }

  return axios.get(cmsUrl + '/articles', { params: getParams })
    .then((response) => {
      if (
        Object.prototype.toString.call(response) !== '[object Object]' ||
        (
          response &&
          typeof response.success === 'boolean' &&
          !response.success
        )
      ) {
        return false
      }

      return response.articles
    })
    .catch(() => {
      return false
    })
}