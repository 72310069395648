<template>
  <div class="membership-layer-mask" v-if="showBanner" @click="close">
    <div class="layer">
      <v-btn absolute icon top right @click="close">
        <img src="@/assets/images/icons/x.svg" alt="" />
      </v-btn>

      <strong>Kedves {{ currentUser.seller_profile.title }}!</strong>
      <br/><br/>

      <template v-if="!sellerProfileIsApproved">
        <p>
          Boltod jóváhagyásra vár.
          A boltod elfogadását követően tudod rendezni a havi 1100 Ft-os kezelési díjat, mely segítségével posztolhatsz Facebook csoportunkban!
        </p>
      </template>
      <template v-else>
        <p>
          2023. január 9-től weboldalunkon az eladói márkaprofil havi 1100 Ft /hó kezelési díj ellenében vehető igénybe. Új boltként ennek befizetésével tudsz regisztrálni, a már regisztrált eladók a havi kezelési díj megváltása után maradnak elérhetőek.
        </p>
        <v-btn class="action" @click="onClickButton">Előfizetek</v-btn>
      </template>

    </div>
  </div>
</template>

<script>
import UserMixin from '@/mixins/User';
import MembershipMixin from '@/mixins/Membership';

export default {
  name: 'MembershipLayer',
  mixins: [UserMixin, MembershipMixin],
  data() {
    return {
      show: false,
    }
  },
  computed: {
    hasSellerProfile() {
      return this.currentUser ? !!this.currentUser.seller_profile : false;
    },
    sellerProfileIsApproved() {
      return this.hasSellerProfile && this.currentUser.seller_profile.status === 1;
    },
    showBanner() {
      return this.show && this.hasSellerProfile && !this.currentUserIsAdmin && !this.hasActiveMembership;
    },
  },
  mounted() {
    this.show = sessionStorage.getItem('seen-membership-layer') ? false : true;
  },
  methods: {
    close() {
      sessionStorage.setItem('seen-membership-layer', true);
      this.show = false;
    },
    onClickButton() {
      sessionStorage.setItem('seen-membership-layer', true);
      this.show = false;

      this.$router.push('/profil/elofizetes');
    },
  }
}
</script>
