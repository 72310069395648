import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VTabs,
    {
      staticClass: "mobile-navigation-tabs-component fixed",
      attrs: { centered: "", grow: "", "slider-size": "3", height: "60" },
    },
    [
      _c(VTab, { attrs: { to: "/piacter" } }, [_vm._v("Felfedezés")]),
      _c(VTab, { attrs: { to: "/terkep" } }, [_vm._v("Térkép")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }