// import UserMixin from '@/mixins/User';

export default {
  // mixins: [UserMixin],
  computed: {
    membership() {
      return this.currentUser && this.currentUser.membership ? this.currentUser.membership : null;
    },
    membershipIsActive() {
      return this.membership ? !!this.membership.membership_is_active : false;
    },
    membershipRecurringIsActive() {
      return this.membership && typeof this.membership.recurring_is_active == 'boolean' ? !!this.membership.recurring_is_active : false;
    },
    membershipValidDate() {
      return this.membership ? this.membership.membership_valid_date : null;
    },
    membershipNextCharge() {
      return this.membership ? this.membership.stripe_next_charge : null;
    },
    nextChargeDate() {
      if (!this.membershipNextCharge) {
        return null;
      }

      const year = this.membershipNextCharge.substring(0, 4);
      const month = parseInt(this.membershipNextCharge.substring(5, 7));
      const day = this.membershipNextCharge.substring(8, 10);

      const monthNames = ['', 'január', 'február', 'március', 'április', 'május', 'június',
        'július', 'augusztus', 'szeptember', 'október', 'november', 'december'];

      return year + '. ' + monthNames[month] + ' ' + day + '.';
    },
    membershipIsValidDate() {
      if (!this.membershipValidDate) {
        return false;
      }

      const membershipValidDate = new Date(this.membershipValidDate);
      const now = new Date();

      return membershipValidDate > now;
    },
    hasActiveMembership() {
      return this.membershipRecurringIsActive || this.membershipIsValidDate;
    },
  }
}
