import axios from 'axios';
import { store } from '../store';

export const metricsService = {
  get,
  sendEventToUser,
  sendEventToUserAndOwner
};

function get() {
  return axios.get(process.env.VUE_APP_BACKEND_API + '/metrics');
}

/**
 * Post user event to backend
 */
function sendEventToUser(action, data) {
  const loggedIn   = store.getters['auth/loggedIn'];
  const url        = process.env.VUE_APP_BACKEND_API + '/metrics';

  if (loggedIn) {
    return axios.post(url, { action, data });
  }
}

/**
 * Post user event to backend
 * (if we want to save the event for user and product owner too)
 */
function sendEventToUserAndOwner(entityType, action, entityId, data) {
  const url = process.env.VUE_APP_BACKEND_API + '/metrics/' + entityType;

  return axios.post(url, { action, entityId, data });
}
