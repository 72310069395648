export const baseRoutes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      title: 'Főoldal',
      transitionName: 'fade',
      cssClass: 'homepage',
      public: true
    }
  },

  {
    path: '/piacter',
    name: 'Piactér',
    component: () => import('@/views/Main.vue'),
    children: [
      {
        path: '',
        name: 'Explore',
        component: () => import('@/views/List.vue'),
        meta: {
          title: 'Piactér',
          transitionName: 'inside-fade',
          cssClass: 'explore',
          public: true
        }
      },
      {
        path: '/termek/:slug',
        name: 'Product',
        component: () => import('@/views/Product.vue'),
        meta: {
          title: 'Termék adatlapja',
          transitionName: 'inside-fade',
          cssClass: 'product',
          public: true
        }
      },
      {
        path: '/bolt/:slug',
        name: 'Seller',
        component: () => import('@/views/Seller.vue'),
        meta: {
          title: 'Eladó adatlapja',
          transitionName: 'inside-fade',
          cssClass: 'seller',
          public: true
        }
      }
    ]
  },

  {
    path: '/terkep',
    name: 'Map',
    component: () => import('@/views/Map.vue'),
    meta: {
      title: 'Térkép',
      transitionName: 'fade',
      cssClass: 'map',
      public: true
    }
  },

  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import('@/views/Impressum.vue'),
    meta: {
      title: 'Impresszum',
      transitionName: 'fade',
      cssClass: 'homepage',
      public: true
    }
  },

  {
    path: '/segitseg',
    name: 'Help',
    component: () => import('@/views/Help.vue'),
    meta: {
      title: 'Segítség',
      transitionName: 'fade',
      public: true
    }
  },

  {
    path: '/feliratkozas',
    name: 'NewsletterSub',
    component: () => import('@/views/NewsletterSub.vue'),
    meta: {
      title: 'Feliratkozás',
      transitionName: 'fade',
      cssClass: 'newslettersub',
      public: true
    }
  },

  {
    path: '/metrics',
    name: 'Metrics',
    component: () => import('@/views/Metrics.vue'),
    meta: {
      title: 'Metrics',
      transitionName: 'fade',
      cssClass: 'metrics'
    }
  },
  {
    path: '/elofizetes',
    name: 'Subscription',
    component: () => import('@/views/Subscription.vue'),
    meta: {
      title: 'Előfizetés',
      transitionName: 'fade',
      cssClass: 'subscription',
      public: true
    }
  },
  {
    path: '/magazin',
    name: 'Magazine',
    component: () => import('@/views/Magazine.vue'),
    meta: {
      title: 'Magazin',
      transitionName: 'fade',
      //cssClass: "subscription",
      public: true
    }
  },
  {
    path: '/magazin/preview/:id',
    name: 'CikkPreview',
    component: () => import('@/views/ArticleView.vue'),
    meta: {
      title: 'Magazin',
      transitionName: 'fade',
      //cssClass: "subscription",
      public: true
    }
  },
  {
    path: '/magazin/:slug',
    name: 'Cikk',
    component: () => import('@/views/ArticleView.vue'),
    meta: {
      title: 'Magazin',
      transitionName: 'fade',
      //cssClass: "subscription",
      public: true
    }
  },
  {
    path: '/galeria/:galleryId',
    redirect: '/galeria/:galleryId/1'
  },
  {
    path: '/galeria/:galleryId/:imageNo',
    name: 'GalleryPage',
    component: () => import('@/views/Gallery.vue'),
    meta: {
      title: 'Galéria',
      transitionName: 'fade',
      cssClass: 'gallery',
      public: true
    }
  },
  { path: '*', redirect: '/' }
];
