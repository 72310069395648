import { categoryService } from '../../services';

const state = {
  categories: []
}

const getters = {
  categories(state) {
    return state.categories;
  }
}

const actions = {
  load({commit}) {
    categoryService.getCategories()
      .then(items => {
        if (items) {
          commit('setCategories', items);
        }
      });
  },
}

const mutations = {
  setCategories(state, value) {
    state.categories = Object.freeze(value);
  }
}

export const category = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
