<template>
  <div class="ad-zone" :id="id" style="display: none;"></div>
</template>

<script>
  export default {
    name: 'DfpSlot',
    props: {
      id: {
        type: String,
        required: true
      },
      adUnit: {
        type: String,
        required: true
      },
      sizes: {
        type: Array,
        required: true
      },
    },
    beforeCreate() {
      if (!(window.googletag && window.googletag.cmd)) {
        return;
      }

      window.DFPSlots = window.DFPSlots || {};

      window.googletag.cmd.push(() => {
        const destroyableSlots = [];

        Object.keys(window.DFPSlots).map(divId => {
          if (!document.getElementById(divId)) {
            destroyableSlots.push(window.DFPSlots[divId]);
          }
        });

        window.googletag.destroySlots(destroyableSlots);
      });
    },
    mounted() {
      if (!(window.googletag && window.googletag.cmd)) {
        return;
      }

      setTimeout(() => {
        window.googletag.cmd.push(() => {
          const slot = window.googletag.defineSlot('/46633415/' + this.adUnit, this.sizes, this.id)
          if (!slot) {
            return;
          }

          window.DFPSlots[this.id] = slot;

          slot.addService(window.googletag.pubads());
          window.googletag.pubads().enableSingleRequest();
          window.googletag.enableServices();
          window.googletag.display(this.id);
        });
      }, 100);
    },
  };
</script>
