import axios from 'axios';
import { store } from '@/store';

export const seasonalService = {
  getAllProduct,
  createProduct,
  updateProduct,
  getProduct,
  removeProduct,
  checkRegnumberExistence,
  updateProductFlag,
  updateProductStatus,
  updateProductActiveStatus,
  getAllItems,
  createItem,
  updateItem,
  getItem,
  removeItem,
  getCategories,
  getCategory,
  createCategory,
  updateCategory,
  removeCategory,
  statistics,
  updateCategoriesOrder
};


function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
}

/**
 * Post data of product
 *
 * @param {Object} postData
 *   Form data
 */
function createProduct(postData) {
  const url  = process.env.VUE_APP_BACKEND_API + '/event/seasonal/product';
  const formData = new FormData();

  buildFormData(formData, postData);

  return axios.post(
    url,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp;
    })
    .catch(error => store.dispatch('alert/show', error.message));
}

/**
 * Post data of product
 *
 * @param {String, Number} id
 *   Product ID
 * @param {Object} postData
 *   Form data
 */
function updateProduct(id, postData) {
  const url  = process.env.VUE_APP_BACKEND_API + '/event/seasonal/product/' + id;
  const formData = new FormData();

  buildFormData(formData, postData);

  return axios.put(
    url,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp;
    })
    .catch(error => store.dispatch('alert/show', error.message));
}

/**
 * Updates a single property of product
 *
 * @param {String, Number} id
 *   Product ID
 * @param {Object} flag
 *   Form data
 */
function updateProductFlag(id, flag) {
  const url  = process.env.VUE_APP_BACKEND_API + '/event/seasonal/product/' + id + '/' + flag.field + '/' + flag.value;
  return axios.put(url)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp;
    })
    .catch(error => store.dispatch('alert/show', error.message));
}

/**
 * Updates status field of product
 *
 * @param {String, Number} id
 *   Product ID
 * @param {Number} status
 *   Status value
 */
function updateProductStatus(id, status) {
  return this.updateProductFlag(id, {field: 'status', value: status});
}

/**
 * Updates status field of product
 *
 * @param {String, Number} id
 *   Product ID
 * @param {Number} active
 *   Is_active value
 */
function updateProductActiveStatus(id, active) {
  return this.updateProductFlag(id, {field: 'active', value: active});
}

/**
 * Gets all seasonal products
 *
 * @param {String|Number} seasonalItemId
 * @param {String} searchString
 * @param {String} categoryId
 * @param {Number} isActive
 * @param {Number} status
 * @param {String} order
 * @param {String} sort
 */
function getAllProduct({seasonalItemId = 0, searchString = '', categoryId = '', isActive = null, status = null, order = null, sort = null, noCache = false, randomize = true}) {
  const url = process.env.VUE_APP_BACKEND_API + '/event/seasonal/all';

  let params = {
    s: searchString,
    cid: categoryId,
    siid: seasonalItemId,
    randomize: randomize ? 1 : 0
  };
  if (isActive !== null) {
    params.active = isActive;
  }
  if (status !== null) {
    params.status = status;
  }
  if (order !== null) {
    params.order = order;
  }
  if (sort !== null) {
    params.sort = sort;
  }

  let headers = {};
  if (noCache) {
    headers['Cache-Control'] = 'no-cache';
  }

  return axios.get(url, {params, headers})
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.products;
    });
}

/**
 * Get a seasonal product
 *
 * @param {String} pid
 */
function getProduct(pid) {
  const url = process.env.VUE_APP_BACKEND_API + '/event/seasonal/product/' + pid;

  return axios.get(url)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.product;
    });
}

/**
 * Deletes a seasonal product
 *
 * @param {String} pid
 */
function removeProduct(pid) {
  const url = process.env.VUE_APP_BACKEND_API + '/event/seasonal/product/' + pid;

  return axios.delete(url)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp || (resp && !resp.error)) {
        throw new Error('Ismeretlen hiba történt.');
      }

      return resp;
    });
}

/**
 * Checks regnumber is already existed for this seasonal item or not.
 *
 * @param {String} seasonalItemId
 * @param {String} regnumber
 */
function checkRegnumberExistence(seasonalItemId, regnumber) {
  const url = process.env.VUE_APP_BACKEND_API + '/event/seasonal/item/' + seasonalItemId + '/regnumber/' + regnumber;

  return axios.get(url)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.count > 0;
    });
}

/**
 * Gets categories
 *
 * @param {String|Number} seasonalItemId
 */
function getCategories({seasonalItemId = 0}) {
  const url = process.env.VUE_APP_BACKEND_API + '/event/seasonal/category/all';

  return axios.get(url, {params: {siid: seasonalItemId}})
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.categories;
    });
}

/**
 * Gets a seasonal category
 *
 * @param {String} cid
 */
function getCategory(cid) {
  const url = process.env.VUE_APP_BACKEND_API + '/event/seasonal/category/' + cid;

  return axios.get(url)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.category;
    });
}

/**
 * Creates a seasonal category.
 */
function createCategory(postData) {
  const url = process.env.VUE_APP_BACKEND_API + '/event/seasonal/category';
  const formData = new FormData();
  buildFormData(formData, postData);

  return axios.post(url, formData)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.category;
    });
}

/**
 * Updates a seasonal category by its ID.
 */
function updateCategory(cid, postData) {
  const url = process.env.VUE_APP_BACKEND_API + '/event/seasonal/category/' + cid;
  const formData = new FormData();
  buildFormData(formData, postData);

  return axios.put(url, formData)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.category;
    });
}

/**
 * Deletes a seasonal category
 *
 * @param {String} cid
 */
function removeCategory(cid) {
  const url = process.env.VUE_APP_BACKEND_API + '/event/seasonal/category/' + cid;

  return axios.delete(url)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.category;
    });
}

/**
 * Gets all seasonal items
 */
function getAllItems() {
  const url = process.env.VUE_APP_BACKEND_API + '/event/seasonal/item/all';

  return axios.get(url)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.items;
    });
}

/**
 * Gets a seasonal item
 *
 * @param {String} siid
 */
function getItem(siid) {
  const url = process.env.VUE_APP_BACKEND_API + '/event/seasonal/item/' + siid;

  return axios.get(url)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.item;
    });
}

/**
 * Creates a seasonal item.
 */
function createItem(postData) {
  const url = process.env.VUE_APP_BACKEND_API + '/event/seasonal/item';
  const formData = new FormData();
  buildFormData(formData, postData);

  return axios.post(url, formData)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.item;
    });
}

/**
 * Updates a seasonal item by its ID.
 */
function updateItem(siid, postData) {
  const url = process.env.VUE_APP_BACKEND_API + '/event/seasonal/item/' + siid;
  const formData = new FormData();
  buildFormData(formData, postData);

  return axios.put(url, formData)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.item;
    });
}

/**
 * Deletes a seasonal item
 *
 * @param {String} siid
 */
function removeItem(siid) {
  const url = process.env.VUE_APP_BACKEND_API + '/event/seasonal/item/' + siid;

  return axios.delete(url)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.item;
    });
}

/**
 * Retrieves statistics from a seasonal item
 *
 * @param {String} siid
 */
function statistics(siid) {
  const url = process.env.VUE_APP_BACKEND_API + '/event/seasonal/item/' + siid + '/stat';

  return axios.get(url)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.data;
    })
}

function updateCategoriesOrder(seasonalItemId, postData) {
  const url = process.env.VUE_APP_BACKEND_API + '/event/seasonal/category/' + seasonalItemId + '/update-orders';
  const formData = new FormData();
  buildFormData(formData, postData);

  return axios.put(url, formData)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a művelet végrehajtásakor.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp;
    })
}
