export const productRoutes = [
  {
    path: '/termek/feltoltes',
    name: 'AddProduct',
    component: () => import('@/views/ProductForm.vue'),
    meta: {
      title: 'Termék feltöltése',
      transitionName: 'inside-fade',
      cssClass: 'product-form product-add'
    }
  },
  // {
  //   path: '/termek/:slug',
  //   name: 'Product',
  //   component: () => import('@/views/Product.vue'),
  //   meta: {
  //     title: 'Termék adatlapja',
  //     transitionName: 'inside-fade',
  //     cssClass: 'product',
  //     public: true
  //   }
  // },
  {
    path: '/termek/:slug/szerkesztes',
    name: 'EditProduct',
    component: () => import('@/views/ProductForm.vue'),
    meta: {
      title: 'Termék szerkesztése',
      transitionName: 'inside-fade',
      cssClass: 'product-form product-edit'
    }
  },

  {
    path: '/product/:slug',
    redirect: { name: 'Seller' }
  },
];
