<template>
  <div v-if="isMobile() && showHamburger" class="mobile-header">
    <div
      :class="{ 'hamburger-menu--toggler': true, opened: mobileMenuOpened }"
      @click="toggleMobileMenu"
    >
      <span class="line first" />
      <span class="line second" />
      <span class="line third" />
    </div>
    <div v-if="mobileMenuOpened" class="mobile-menu">
      <router-link
        to="/piacter"

        :active-class="exploreIsActive ? `router-link-active` : ``"
        v-on:click.native="sendEvent('Piactér')"
      >
        <span>Piactér</span>
      </router-link>

      <router-link to="/elofizetes" v-on:click.native="sendEvent('Előfizetés')">
        <span>Előfizetés</span>
      </router-link>

      <router-link to="/magazin" v-on:click.native="sendEvent('Magazin')">
        <span>Magazin</span>
      </router-link>

      <router-link v-if="dynamicItem" :to="dynamicItem.path">
        <span>{{ dynamicItem.label }}</span>
      </router-link>

      <div class="divider" />

      <!-- Vendég -->
      <template v-if="!loggedIn">
        <router-link to="/auth/login" v-on:click.native="sendEvent('Belépés')">
          <span>Belépés</span>
        </router-link>

        <router-link to="/auth/register" v-on:click.native="sendEvent('Regisztráció')">
          <span>Regisztráció</span>
        </router-link>
      </template>
      <!-- // Vendég -->

      <!-- Belépett felhasználó -->
      <template v-else>
        <router-link to="/profil" v-on:click.native="sendEvent('Profil')">
          <span>{{ profileLabel }}</span>
        </router-link>

        <router-link to="/auth/logout" v-on:click.native="logout">
          <span>Kilépés</span>
        </router-link>
      </template>
      <!-- // Belépett felhasználó -->
    </div>
  </div>
  <!-- // Mobil menü -->

  <!-- Desktop menü -->
  <nav v-else class="header-navigation">
    <router-link
      to="/piacter"

      :active-class="exploreIsActive ? `router-link-active` : ``"
      v-on:click.native="sendEvent('Piactér')"
    >
      <span>Piactér</span>
    </router-link>

    <router-link to="/elofizetes" v-on:click.native="sendEvent('Előfizetés')">
      <span>Előfizetés</span>
    </router-link>

    <router-link to="/magazin" v-on:click.native="sendEvent('Magazin')">
      <span>Magazin</span>
    </router-link>

    <router-link v-if="dynamicItem" :to="dynamicItem.path">
      <span>{{ dynamicItem.label }}</span>
    </router-link>

    <!-- Vendég -->
    <template v-if="!loggedIn">
      <div class="auth-items">
        <router-link to="/auth/login" class="filled" v-on:click.native="sendEvent('Belépés')">
          <span>Belépés</span>
        </router-link>

        <router-link to="/auth/register" v-on:click.native="sendEvent('Regisztráció')">
          <span>Regisztráció</span>
        </router-link>
      </div>
    </template>
    <!-- // Vendég -->

    <!-- Belépett felhasználó -->
    <template v-else>
      <div class="auth-items">
        <router-link to="/profil" class="filled" v-on:click.native="sendEvent('Profil')">
          <span>{{ profileLabel }}</span>
        </router-link>

        <router-link to="/auth/logout" v-on:click.native="logout">
          <span>Kilépés</span>
        </router-link>
      </div>
    </template>
    <!-- // Belépett felhasználó -->


    <!-- Átmenetileg kiszedett menüpontok -->
    <!--
    <router-link to="/vegyeltavaszit" v-on:click.native="sendEvent('Vegyéltavaszit')">
      <span>Vásár</span>
    </router-link>

    <router-link to="/terkep" v-on:click.native="sendEvent('Térkép')">
      <span>Térkép</span>
    </router-link>

    <router-link to="/segitseg" v-on:click.native="sendEvent('Segítség')">
      <span>Segítség</span>
    </router-link>
    -->

  </nav>
  <!-- // Desktop menü -->
</template>

<script>
import BrowserMixin from '@/mixins/Browser';
import MembershipMixin from '@/mixins/Membership';
import UserMixin from '@/mixins/User';
import { sendGaEvent } from '@/utils/send-ga-event';

export default {
  name: 'Navigation',
  mixins: [BrowserMixin, MembershipMixin, UserMixin],
  data: () => ({
    mobileMenuOpened: false,
  }),
  props: {
    showHamburger: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    exploreIsActive() {
      const routeName = this.$route.name;

      return (
        routeName === 'Explore' ||
        routeName === 'Seller' ||
        routeName === 'Product'
      );
    },
    dynamicItem() {
      if (this.currentUser && !this.currentUser.seller_profile) {
        return { path: '/bolt/letrehozas', label: 'Boltot nyitok' };
      }

      return null;
    },
    profileLabel() {
      return this.currentUser && this.membershipIsActive
        ? 'Profil / Előfizetés'
        : 'Profil';
    },
  },
  watch: {
    mobileMenuOpened() {
      if (this.mobileMenuOpened) {
        document.querySelector('html').classList.add('no-scroll');
      } else {
        document.querySelector('html').classList.remove('no-scroll');
      }
    },
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenuOpened = !this.mobileMenuOpened;
    },
    logout() {
      this.sendEvent('Kilépés');
      this.$store.dispatch('auth/logout');
    },
    sendEvent(category) {
      this.mobileMenuOpened = false;
      sendGaEvent('menu', category);
    },
  },
};
</script>
