const state = {
  categories: [],
  products: true,
  sellers: true,
}

const getters = {
  categories(state) {
    return state.categories;
  },
  products(state) {
    return state.products;
  },
  sellers(state) {
    return state.sellers;
  },
}

const actions = {
  toggleCategory({commit, state, rootState}, value) {
    const ids = rootState.category.categories.map(value => value.id);
    if (ids.indexOf(value) === -1) {
      return;
    }

    if (state.categories.indexOf(value) === -1) {
      commit('selectCategory', value);
    }
    else {
      commit('unselectCategory', value);
    }
  }
}

const mutations = {
  selectCategory(state, value) {
    if (state.categories.indexOf(value) === -1) {
      state.categories.push(value);
    }
  },
  unselectCategory(state, value) {
    const index = state.categories.indexOf(value);
    if (index !== -1) {
      state.categories.splice(index, 1);
    }
  },
  setCategories(state, value) {
    state.categories = value;
  },
  setProducts(state, value) {
    state.products = value;
  },
  setSellers(state, value) {
    state.sellers = value;
  },
}

export const categoryFilter = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
