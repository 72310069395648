import Vue from 'vue';
import Vuex from 'vuex';

import { app } from './modules/app';
import { alert } from './modules/alert';
import { auth } from './modules/auth';
import { seasonal } from './modules/seasonal';
import { category } from './modules/category';
import { categoryFilter } from './modules/categoryFilter';
import { membership } from './modules/membership';
import { product } from './modules/product';
import { searchInput } from './modules/searchInput';
import { user } from './modules/user';

import userPlugin from './plugins/user';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    app,
    alert,
    auth,
    seasonal,
    category,
    categoryFilter,
    membership,
    product,
    searchInput,
    user,
  },
  plugins: [
    userPlugin,
  ],
});
