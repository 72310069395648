import { membershipService } from '../../services';

const state = {
  membership: null
}

const getters = {
  membership(state) {
    return state.membership;
  }
}

const actions = {
  getMembership({commit}, {userId}) {
    return membershipService.getMembership(userId)
      .then(resp => {
        if (resp && resp.membership) {
          commit('setMembershipData', resp.membership);
        }
      });
  },
}

const mutations = {
  setMembershipData(state, value) {
    state.membership = Object.freeze(value);
  }
}

export const membership = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
