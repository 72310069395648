import { VApp } from 'vuetify/lib/components/VApp';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VApp,
    { class: _vm.cssClasses, attrs: { id: "app" } },
    [
      _c("sticky-banner"),
      _c("user-membership-banner"),
      _c("membership-layer"),
      _c("snackbar"),
      _c("app-header"),
      _vm.mobileNavigationTabsIsVisible
        ? _c("mobile-navigation-tabs")
        : _vm._e(),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "app-loader" },
            [
              _c("spinner", {
                staticClass: "mr-8",
                attrs: { size: "48px", color: "#676767" },
              }),
              _vm._v(" Töltés.. "),
            ],
            1
          )
        : [
            _c(
              "transition-page",
              [_c("keep-alive", [_c("router-view")], 1)],
              1
            ),
          ],
      _vm.updateExists
        ? _c("div", { staticClass: "notification--sw-updated" }, [
            _c("p", [
              _vm._v(
                "Frissítés érhető el az alkalmazáshoz. A legfrissebb verzió eléréséhez kattintson a FRISSÍTÉS gombra."
              ),
            ]),
            _c("a", { on: { click: _vm.refreshApp } }, [_vm._v("Frissítés")]),
          ])
        : _vm._e(),
      _c("cookie-law", { attrs: { buttonText: "Megértettem" } }, [
        _c("div", { attrs: { slot: "message" }, slot: "message" }, [
          _vm._v(
            " Weboldalunkon cookie-kat használunk annak érdekében, hogy jobban megismerjük a felhasználói viselkedést és érdeklődést, valamint ezek segítségével személyre szabjuk és javítsuk a reklámokat. Weboldalunk használatával ezen feltételeket ön automatikusan elfogdja. "
          ),
          _c(
            "a",
            {
              attrs: {
                href: "https://adatvedelem.marquardmedia.hu/adatvedelmi_tajekoztato.pdf",
                target: "_blank",
              },
            },
            [
              _vm._v(
                "További információkat az adatkezelési tájékoztatóban olvashat."
              ),
            ]
          ),
        ]),
      ]),
      _c("dfp-slot", {
        attrs: { id: "layer", adUnit: "vh_layer", sizes: [[1, 1]] },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }