<template>
   <div class="app-sticky-banner" v-if="showBanner" @click="goToDestination()">
      Július 29-től Követő csomagunk tartalma bővült, ára <strong>1100</strong> Ft-ra emelkedik. 
      A többi csomagunk bővült tartalom mellett, ugyanazon az áron elérhető. Részletekért kattints >
    </div>
</template>

<script>
export default {
  name: 'StickyBanner',

  computed: {
    showBanner() {
      return false;
    }
  },
  methods: {
    goToDestination() {
      this.$router.push('/magazin/koveto-csomag-ujitasok-176519')
    }
  }
}
</script>