export const sellerRoutes = [
  // {
  //   path: '/bolt/:slug',
  //   name: 'Seller',
  //   component: () => import('@/views/Seller.vue'),
  //   meta: {
  //     title: 'Eladó adatlapja',
  //     transitionName: 'inside-fade',
  //     cssClass: 'seller',
  //     public: true
  //   }
  // },
  {
    path: '/bolt/letrehozas',
    name: 'CreateSeller',
    component: () => import('@/views/SellerForm.vue'),
    meta: {
      title: 'Eladói profil létrehozása',
      transitionName: 'inside-fade',
      cssClass: 'seller-form seller-create'
    }
  },
  {
    path: '/bolt/:slug/szerkesztes',
    name: 'EditSeller',
    component: () => import('@/views/SellerForm.vue'),
    meta: {
      title: 'Eladói profil szerkesztése',
      transitionName: 'inside-fade',
      cssClass: 'seller-form seller-edit'
    }
  }
];
