import axios from 'axios';
import { store } from '../store';

const isDev = process.env.NODE_ENV !== 'production';

export default {
  getAttachedCards() {
    const url = process.env.VUE_APP_BACKEND_API + '/payment/stripe/cards';

    return axios
      .get(url)
      .then((resp) => {
        if (resp instanceof Error) {
          throw new Error(isDev ? resp.message : "Hiba az adatok betöltésekor.");
        }

        if (!resp.success) {
          throw new Error(resp.error);
        }

        return resp.cards
      })
      .catch((error) => {
        store.dispatch("alert/show", error.message);
        return false;
      });
  },
  getStripeCurrentCard() {
    const url = process.env.VUE_APP_BACKEND_API + '/payment/stripe/current-card';

    return axios
      .get(url)
      .then((resp) => {
        if (resp instanceof Error) {
          throw new Error(isDev ? resp.message : "Hiba az adatok betöltésekor.");
        }

        if (!resp.success) {
          throw new Error(resp.error);
        }

        return resp.card
      })
      .catch((error) => {
        store.dispatch("alert/show", error.message);
        return false;
      });
  },

  /**
   * @deprecated
   */
  updateStripeCurrentCard(token) {
    const url = process.env.VUE_APP_BACKEND_API + '/payment/stripe/current-card';

    return axios
      .put(url, { token })
      .then((resp) => {
        if (resp instanceof Error) {
          throw new Error(isDev ? resp.message : "Hiba történt a küldés során.");
        }

        if (!resp.success) {
          throw new Error(resp.error);
        }

        return resp
      })
      .catch((error) => {
        store.dispatch("alert/show", error.message);
        return false;
      });
  },

  deleteStripeSpecificCard(paymentMethodId) {
    const url = process.env.VUE_APP_BACKEND_API + '/payment/stripe/card/' + paymentMethodId;

    return axios
      .delete(url)
      .then((resp) => {
        if (resp instanceof Error) {
          throw new Error(isDev ? resp.message : "Hiba történt a kártya törlése közben.");
        }

        if (!resp.success) {
          throw new Error(resp.error);
        }

        return resp
      });
  },

  createCardConfirmSetupIntent() {
    const url = process.env.VUE_APP_BACKEND_API + '/payment/stripe/card-confirmation';

    return axios
      .post(url)
      .then((resp) => {
        if (resp instanceof Error) {
          throw new Error(isDev ? resp.message : "Hiba történt a küldés során.");
        }

        if (!resp.success) {
          throw new Error(resp.error);
        }

        return resp
      })
      .catch((error) => {
        store.dispatch("alert/show", error.message);
        return false;
      });
  },

  setPaymentMethodAsDefault(paymentMethodId) {
    const data = { payment_method_id: paymentMethodId };
    const url = process.env.VUE_APP_BACKEND_API + '/payment/stripe/set-as-default';

    return axios
      .post(url, data)
      .then((resp) => {
        if (resp instanceof Error) {
          throw new Error(isDev ? resp.message : "Hiba történt a küldés során");
        }

        if (!resp.success) {
          throw new Error(resp.error);
        }

        return resp
      })
      .catch((error) => {
        store.dispatch("alert/show", error.message);
        return false;
      });
  },
}
