import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from '../store'

import {baseRoutes} from './_base.js';
import {authRoutes} from './_auth.js';
import {productRoutes} from './_product.js';
import {sellerRoutes} from './_seller.js';
import {profileRoutes} from './_profile.js';
import {eventsRoutes} from './_events.js';
import {seasonalRoutes} from './_seasonal.js';

Vue.use(VueRouter)

let routes = [];
routes = routes.concat(productRoutes);
routes = routes.concat(sellerRoutes);
routes = routes.concat(baseRoutes);
routes = routes.concat(authRoutes);
routes = routes.concat(profileRoutes);
routes = routes.concat(eventsRoutes);
routes = routes.concat(seasonalRoutes);

function hasValidAdminAuth(toRoute) {
  return localStorage.getItem('aa_' + toRoute.meta.restricted) === 'true';
}

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    // If we stay on the same view and ID changes only and wants the have the
    // same scroll position.
    if (from.name === to.name) {
      return {};
    }

    return new Promise(resolve => {
      setTimeout(() => {
        if (savedPosition) {
          resolve(savedPosition);
        }
        else {
          resolve({ x: 0, y: 0 });
        }
      }, 25);
    });
  },
})

router.beforeEach(async (to, from, next) => {
  const loggedIn = store.getters['auth/loggedIn'];

  if (loggedIn && to.name === 'Login') {
    return next('/');
  }

  if (loggedIn || to.meta.public) {
    if (/^(Seasonal)(\w+)?/.test(to.name) && !to.path.includes('/admin/')) {
      to.meta.cssClass += ' easter-2023';

      if (to.name != 'SeasonalIsOver' && router.app && router.app.$seasonals) {
        const currentSeason = router.app.$seasonals[router.app.$season];
        if (currentSeason.ends && new Date() > currentSeason.ends) {
          return next({name: 'SeasonalIsOver'});
        }
      }
    }

    if (
      to.meta
      && to.meta.restricted
      && to.name !== 'AdminAuth'
      && !hasValidAdminAuth(to)
    ) {
      return next({name: 'AdminAuth', query: {'redirect': to.fullPath}});
    }

    next();
  }
  else {
    next('/');
  }
})
