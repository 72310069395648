<template>
  <v-tabs class="mobile-navigation-tabs-component fixed" centered grow slider-size="3" height="60">
    <v-tab to="/piacter">Felfedezés</v-tab>
    <v-tab to="/terkep">Térkép</v-tab>
  </v-tabs>
</template>

<script>
export default {
  name: 'MobileNavigationTabs',
}
</script>
