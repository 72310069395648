import axios from 'axios';
import { store } from '../store';

export const authService = {
  login,
  loginWithFb,
  signup
};

const authUrl = process.env.VUE_APP_SHOPPIEGO_API;

function login(email, password) {
  const url = authUrl + '/auth/login';

  const formData = new FormData();
  formData.append('email', email);
  formData.append('password', password);

  return axios.post(url, formData)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a bejelentkezés során.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.access_token;
    })
    .catch(error => store.dispatch('alert/show', error.message));
}

function loginWithFb(token) {
  const url = authUrl + '/auth/login-with-facebook';

  const formData = new FormData();
  formData.append('access_token', token);
  formData.append('source', 'vegyelhazait.hu');

  return axios.post(url, formData)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a bejelentkezés során.');
      }

      if (!resp.success) {
        throw new Error(resp.error);
      }

      return resp.access_token;
    })
    .catch(error => store.dispatch('alert/show', error.message));
}

function signup(email, password) {
  const url = authUrl + '/auth/signup';

  const formData = new FormData();
  formData.append('email', email);
  formData.append('password', password);
  formData.append('source', 'vegyelhazait.hu');

  return axios.post(url, formData)
    .then(resp => {
      if (resp instanceof Error) {
        throw new Error('Hiba a regisztráció során.');
      }

      if (!resp.success) {
        if (resp.error == 'Az e-mail cím már foglalt') {
          resp.error = 'Az általad megadott e-mail cím már egy JOY Hungary app fiókhoz tartozik.<br/>' +
            'Az ott megadott adataiddal a vegyélhazait.hu-ra is be tudsz lépni.';
        }

        throw new Error(resp.error);
      }

      return resp.access_token;
    })
    .catch(error => store.dispatch('alert/show', error.message));
}
