const state = {
	message: null,
  options: {}
}

const getters = {

}

const actions = {
  show ({commit}, payload) {
    let options = {};
    let message = payload;
    if (typeof payload === 'object') {
      message = payload.message;
      options = payload;
      if (options.message) {
        delete options.message;
      }
    }

		commit('setMessage', {message, options});
	},
	clear ({commit}) {
		commit('clearMessage');
	}
}

const mutations = {
	setMessage (state, {message, options}) {
		state.message = message;
		state.options = options;
	},
	clearMessage (state) {
		state.message = null;
	}
}

export const alert = {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
