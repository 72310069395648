import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#6A8EA8',
        secondary: '#90A4AE',
        accent: '#37474F',
      },
      dark: {
        primary: '#6A8EA8',
        secondary: '#90A4AE',
        accent: '#37474F',
      },
    },
  },
})
